<template>
    <div
        class="tw-border tw-border-gray-300 tw-rounded-lg tw-p-3 tw-overflow-x-auto"
    >
        <table class="tw-w-full">
            <thead>
                <tr>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2 tw-w-3"
                    >
                        {{ $t("bookingPickup.no") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                         <p class="required tw-mb-0">{{ $t("bookingPickup.location") }}</p>
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        <p class="required tw-mb-0">{{ $t("bookingPickup.receiverPhone") }}</p>
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        {{ $t("bookingPickup.qty") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        <p class="required tw-mb-0">{{ $t("bookingPickup.purchaseAmount") }}</p>
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        {{ $t("bookingPickup.extraFee") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        {{ $t("bookingPickup.packageType") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        {{ $t("bookingPickup.vehicleType") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        {{ $t("bookingPickup.zone") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                    >
                        {{ $t("bookingPickup.paidBy") }}
                    </th>
                    <th class="tw-w-8 tw-py-2 tw-px-1">
                        <a
                            @click.prevent="addRow"
                            href="#"
                            class="tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-full tw-text-white"
                            ><i class="fa fa-plus"></i
                        ></a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(d, index) in model.booking_detail" :key="index"
                   :class="{ 'tw-bg-gray-100': index % 2 != 0 }"
                >
                    <td class="tw-text-center">{{ index + 1 }}</td>
                    <td class="tw-px-3 tw-py-2">
                        <input
                            class="tw-outline-none tw-rounded tw-border-r tw-border tw-py-2 tw-px-2 tw-w-full"
                            :class="
                                errors.has(
                                    'booking_detail.' + index + '.destination'
                                )
                                    ? 'tw-border-red-700'
                                    : 'tw-border-gray-300'
                            "
                            type="text"
                            style="min-width: 120px"
                            v-model.trim="d.destination"
                            :placeholder="
                                errors.first(
                                    'booking_detail.' + index + '.destination'
                                )
                            "
                        />
                    </td>
                    <td class="tw-px-3 tw-py-2">
                        <input
                            class="tw-outline-none tw-rounded tw-border tw-border-r tw-py-2 tw-px-2 tw-w-full"
                            :class="
                                errors.has(
                                    'booking_detail.' +
                                        index +
                                        '.receiver_phone'
                                )
                                    ? 'tw-border-red-700'
                                    : 'tw-border-gray-300'
                            "
                            style="min-width: 120px"
                            type="number"
                            v-model.trim="d.receiver_phone"
                            :placeholder="
                                errors.first(
                                    'booking_detail.' +
                                        index +
                                        '.receiver_phone'
                                )
                            "
                        />
                    </td>
                    <td class="tw-px-3 tw-py-2 tw-w-24">
                        <input
                            class="tw-outline-none tw-rounded tw-border tw-border-r tw-py-2 tw-px-2 tw-w-full"
                            :class="
                                errors.has(
                                    'booking_detail.' +
                                        index +
                                        '.number_of_package'
                                )
                                    ? 'tw-border-red-700'
                                    : 'tw-border-gray-300'
                            "
                            type="number"
                            style="min-width: 50px"
                            v-model.trim="d.number_of_package"
                            :placeholder="
                                errors.first(
                                    'booking_detail.' +
                                        index +
                                        '.number_of_package'
                                )
                            "
                        />
                    </td>
                    <td class="tw-px-3 tw-py-2 tw-w-40">
                        <input
                            class="tw-outline-none tw-rounded tw-border tw-border-r tw-py-2 tw-px-2 tw-w-full"
                            :class="
                                errors.has(
                                    'booking_detail.' +
                                        index +
                                        '.purchase_amount'
                                )
                                    ? 'tw-border-red-700'
                                    : 'tw-border-gray-300'
                            "
                            type="number"
                            style="min-width: 50px"
                            v-model.trim="d.purchase_amount"
                            :placeholder="
                                errors.first(
                                    'booking_detail.' +
                                        index +
                                        '.purchase_amount'
                                )
                            "
                        />
                    </td>
                    <td class="tw-px-3 tw-py-2 tw-w-40">
                        <input
                            class="tw-outline-none tw-rounded tw-border tw-border-r tw-py-2 tw-px-2 tw-w-full"
                            :class="
                                errors.has(
                                    'booking_detail.' + index + '.extra_fee'
                                )
                                    ? 'tw-border-red-700'
                                    : 'tw-border-gray-300'
                            "
                            type="number"
                            v-model.trim="d.extra_fee"
                            style="min-width: 100px"
                            :placeholder="
                                errors.first(
                                    'booking_detail.' + index + '.extra_fee'
                                )
                            "
                        />
                    </td>
                    <td class="tw-px-3 tw-py-2 tw-w-40">
                        <a-select
                            v-model.number="d.package_type_id"
                            style="min-width: 120px"
                            :options="packageTypeList"
                            class="tw-w-full"
                            :placeholder="$t('bookingPickup.packageType')"
                        ></a-select>
                    </td>
                    <td class="tw-px-3 tw-py-2 tw-w-40">
                        <a-select
                            v-model="d.vehicle_type_id"
                            :options="vehicleTypeList"
                            style="min-width: 100px"
                            class="tw-w-full"
                            :placeholder="$t('bookingPickup.vehicleType')"
                        ></a-select>
                    </td>
                    <td class="tw-px-3 tw-py-2 tw-w-40">
                        <a-select
                            v-model="d.zone_id"
                            :options="zoneList"
                            class="tw-w-full"
                            style="min-width: 100px"
                            :placeholder="$t('bookingPickup.zone')"
                        ></a-select>
                    </td>
                    <td class="tw-px-3 tw-py-2 tw-w-40">
                        <a-select
                            v-model.number="d.fee_paid_by"
                            :placeholder="$t('bookingPickup.paidBy')"
                            :options="[
                                { value: 1, label: 'Buyer' },
                                { value: 2, label: 'Shop' }
                            ]"
                            class="tw-w-full"
                        ></a-select>
                    </td>
                    <td class="tw-w-4 tw-px-3 tw-py-2 tw-text-center">
                        <a
                            href="#"
                            class="tw-text-red-600"
                            @click.prevent="removeRow(index)"
                        >
                            <i class="fa fa-trash"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";

export default {
    name: "booking",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    computed: {
        ...mapState("driver/bookingPickup", ["formModels", "edit_data"]),
        packageTypeList() {
            if (!this.formModels.packageType) return [];

            return this.formModels.packageType.map(el => ({
                value: el.package_type_id,
                label: el.package_type_en
            }));
        },
        vehicleTypeList() {
            if (!this.formModels.vehicleType) return [];

            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }));
        },
        zoneList() {
            if (!this.formModels.zone) return [];

            return this.formModels.zone.map(el => ({
                value: el.zone_id,
                label: el.zone_name
            }));
        }
    },
    created() {
        this.model = this.value;
        // this.addRow();
    },
    methods: {
        addRow() {
            // find service set default to package

            let findShop = this.formModels.shop.find(
                s => s.shop_id == this.model.shop_id
            );

            this.model.booking_detail.push({
                purchase_amount: "",
                // receiver_name: "",
                receiver_phone: "",
                destination: "",
                // destination_geo_location: "",
                package_type_id: 1, // small package
                number_of_package: 1,
                // packaging_fee: "",
                extra_fee: 0,
                // packaging_id: "",
                vehicle_type_id: 1, // motobike
                fee_paid_by:
                    findShop != undefined ? findShop.service_paid_by : 2,
                zone_id: null
                // photo: ""
            });
        },
        removeRow(index) {
            this.model.booking_detail.splice(index, 1);
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
